export default function get_gradient(seed){
    const gradients = [
        "linear-gradient(to right, #9796f0, #fbc7d4)",
        "linear-gradient(to right, #6190e8, #a7bfe8)",
        "linear-gradient(to right, #2980b9, #6dd5fa)",
        "linear-gradient(to right, #ee9ca7, #ffdde1)",
        "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
        // "linear-gradient(to right, #373b44, #4286f4)",
        // "linear-gradient(to right, #b79891, #94716b)",
        // "linear-gradient(to right, #ff0099, #493240)",
        // "linear-gradient(to right, #fc466b, #3f5efb)",
        "linear-gradient(to right, #d9a7c7, #fffcdc)",
        ]


    const k = seed.split("").reverse().join("").charCodeAt(0) % gradients.length;
    const color = gradients[k];
    return color
}