import { Col, Row, Container, Card, FormGroup, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { terms, privacy } from '../config';


export default function Login() {
    const [email, setEmail] = useState("");
    const [email_err, setEmailErr] = useState("");
    const [password, setPassword] = useState("");
    const [password_err, setPasswordErr] = useState("");
    const [visible, setVisible] = useState(false);
    const [sub_err, setSubErr] = useState("");
    const [loading, setLoading] = useState(false);
    const { loginEmailPassword } = useAuth()
    const navigate = useNavigate();

    function change_email(e) {
        setEmail(e.target.value);
        if (!isEmail(e.target.value)) setEmailErr("Invalid Email");
        else setEmailErr("");
    }

    function change_password(e) {
        setPassword(e.target.value);
        if (e.target.value.length > 128) setPasswordErr("Password too long");
        else setPasswordErr("");
    }

    async function submit_form() {
        if (email.length < 1 || password.length < 1) return;
        if (email_err || password_err) return;
        setLoading(true);
        try {
            const res = await loginEmailPassword(email, password);
            navigate.push("/")
        }
        catch (err) {
            switch(err.code){
                case "auth/user-not-found":
                    setSubErr("User is not registered");
                    break;
                default:
                    setSubErr("Invalid credentials");
            }
            setLoading(false);
        }
    }

    const contents = (<>
        <center>
            <h4>Login</h4>
        </center>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control onChange={change_email} value={email} type="email" placeholder="Enter email" />
                <Form.Text>
                    {email_err && (email.length > 0) && (<Alert className="mt-3" variant="danger">
                        {email_err}
                    </Alert>)}
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                    <Form.Control onChange={change_password} value={password} type={visible ? "text" : "password"} placeholder="Enter password" />
                    <Button onClick={() => setVisible(!visible)} variant="secondary">{visible ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</Button>
                </InputGroup>
                <Form.Text>
                    {password_err && (password.length > 0) && (<Alert className="mt-3" variant="danger">
                        {password_err}
                    </Alert>)}
                </Form.Text>
            </Form.Group>
            <Form.Text>
                {sub_err && (<Alert className="mt-3" variant="danger">
                    {sub_err}
                </Alert>)}
            </Form.Text>
            <Button onClick={submit_form} variant="primary" disabled={loading}>Login</Button>
        </Form>
        <hr></hr>
        <center>New around here? <Link to="/register">Register</Link>
        <br></br>
        By proceeding, you agree with our <a href={privacy} target="_blank">Privacy policy</a> and <a href={terms} target="_blank">
            Terms of use
        </a>
        </center>
    </>)
    return (<>
        <Container style={{ height: "100vh", display: "flex" }} className="d-none d-md-flex">
            <Card className="my-auto mx-auto w-50 padded" style={{ maxWidth: "512px" }}>
                {contents}
            </Card>
        </Container>
        <Container style={{ paddingTop: '3em', backgroundColor: 'white', height: '100vh' }} className="d-block d-md-none">
            {contents}
        </Container>
    </>
    )
}
