import React, { useContext, useState, useEffect, useRef } from "react";
import LoadingBar from 'react-top-loading-bar'

const MiscContext = React.createContext();

export function useMisc() {
    return useContext(MiscContext);
}


export function MiscProvider({children, uid}){
    const ref = useRef(null)

    function static_start(){
        try{
            ref.current.staticStart();
        }
        catch(err){
            console.error(err);
        }
    }

    function continious_start(){
        try{
            ref.current.continuousStart();
        }
        catch(err){
            console.error(err);
        }
    }

    function complete(){
        try{
            ref.current.complete();
        }
        catch(err){
            console.error(err);
        }
    }
    
    const value = {
        static_start,
        continious_start, 
        complete
    }

    return (
        <MiscContext.Provider value={value}>
            <LoadingBar color='var(--primary)' ref={ref} />
            {children}
        </MiscContext.Provider>
    )
}