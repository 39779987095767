import React, { useState } from 'react'
import { Row, Col, Button, Form, FormGroup, Alert } from 'react-bootstrap';
import '../css/dash.css'
import { Link } from 'react-router-dom';
import { protectedfetch, useData } from '../contexts/DataContext';
import { add_project } from '../config';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
export default function CreateProject() {
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");

    const [name_err, setNameErr] = useState("");
    const [desc_err, setDescErr] = useState("");
    const [sub_err, setSubErr] = useState("");
    const [loading, setLoading] = useState(false);

    const {currentUser} = useAuth();
    const {data, write_data} = useData();

    const navigate = useNavigate();
    function change_name(e) {
        setName(e.target.value);
        if (!/^[a-z0-9 ]+$/i.test(e.target.value)) setNameErr("Only alpha numerics are allowed in name");
        else if (e.target.value.length > 255) setNameErr("Name too long");
        else if (e.target.value.length < 3) setNameErr("Name too short");
        else setNameErr("");
    }

    function change_desc(e) {
        setDesc(e.target.value);
        if (!/^[a-z0-9 .!?-]+$/i.test(e.target.value)) setDescErr("Invalid characters present in description");
        else if (e.target.value.length > 2000) setDescErr("Description too long");
        else setDescErr("");
    }

    async function submit_form(){
        setSubErr("")
        if(name.length <1 || desc.length < 1) return;
        if(name_err || desc_err) return;
        setLoading(true);
        const res = await protectedfetch(currentUser, add_project + "?name=" + name + "&description=" + desc);
        if(!res.ok) {
            setSubErr("Unable to create a new project");
        }
        else{
            const js = await res.json();
            const temp = await data;
            temp.projects.push(js);
            write_data(temp);
            navigate('/');
        }
        
        setLoading(false)
    }

    return (<>
        <h4>Create Project</h4>
        <Row className="pt-4">
            <Col md={12}>
                <div className="card data-card p-4">
                    <Form>
                        <FormGroup>
                            <Form.Label>
                                Project Name
                            </Form.Label>
                            <Form.Control value={name} onChange={change_name} placeholder="Eg: My new project" type="text" />
                            <Form.Text>
                                {name_err && (name.length > 0) && (<Alert className="mt-3" variant="danger">
                                    {name_err}
                                </Alert>)}
                            </Form.Text>
                        </FormGroup>
                        <FormGroup className="pt-4">
                            <Form.Label>
                                Project Description
                            </Form.Label>
                            <Form.Control value={desc} onChange={change_desc} placeholder="Eg: An amazing project to..." type="text" />
                            <Form.Text>
                                {desc_err && (desc.length > 0) && (<Alert className="mt-3" variant="danger">
                                    {desc_err}
                                </Alert>)}
                            </Form.Text>
                            <Form.Text>
                                {sub_err && (<Alert className="mt-3" variant="danger">
                                    {sub_err}
                                </Alert>)}
                            </Form.Text>
                        </FormGroup>
                        <div className="d-flex pt-4">
                            <Button onClick={() => submit_form()} variant="primary" disabled={loading}>
                                Create Project
                            </Button>
                            <Link className="ms-auto" to="/">
                                <Button variant="secondary" disabled={loading}>
                                    Cancel
                                </Button>
                            </Link>

                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    </>)
}
