import { Navigate, Outlet, Route } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import React from 'react'


export default function ProtectedRoute(props) {
    const { currentUser } = useAuth();
    if (!currentUser) return  (<Navigate to="/login" />)
    return (<Outlet />)
}
