import { Route, Navigate, Outlet } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function UnoriginalRoute(props) {
    const history = useNavigate();
    const { currentUser } = useAuth();
    if (currentUser) return (<Navigate to="/" />)
    return (<Outlet />)
}
