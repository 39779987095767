import React, { useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router';
export default function Logout() {
    const navigate = useNavigate();
    const {logout} = useAuth();
    useEffect(async() => {
        await logout();
        navigate("/login")
    }, [])
    return (
        <div>
            
        </div>
    )
}
