/* global grecaptcha */
import React, { useContext, useState, useEffect } from "react";
import { get_auth_data, login_email_pwd } from "../config";
import { auth, firebase_obj } from "../firebase";
import { sendEmailVerification, GoogleAuthProvider, getRedirectResult, signInWithRedirect, RecaptchaVerifier, getAuth } from "firebase/auth";

const google_provider = new GoogleAuthProvider();
const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [recaptcha, setRecapcha] = useState();

    function signupEmailPassword(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function loginEmailPassword(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function loginGoogle() {
        return signInWithRedirect(auth, google_provider);
    }

    function redirectedResult() {
        return getRedirectResult(auth);
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function setName(name) {
        return currentUser.updateProfile({ displayName: name });
    }

    function sendVerificationEmail() {
        return sendEmailVerification(currentUser);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, []);

    const value = {
        currentUser,
        loginEmailPassword,
        signupEmailPassword,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        setName,
        sendVerificationEmail,
        loginGoogle,
        redirectedResult,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
