import React from 'react'
import "../css/dash.css";
import random_gradient from "../Assets/RandomGradients";
import { Placeholder, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function ProjectCardPlaceholder(props) {
    return (
        <div style={{cursor: 'progress'}} className="project-card card">
            <div style={{ background: "rgba(0, 0, 0, 0.1)" }} className="card-top"></div>
            <div className="p-3 d-flex flex-column h-100">
                <Placeholder className="w-75" as="p" />
                <Placeholder className="w-25" as="p" />

                <p className="mt-auto mb-0">
                    <Placeholder className="w-25" as="p" />
                </p>
            </div>
        </div>
    )
}

export default function ProjectCard(props) {

    const color = random_gradient(props.data.uid);

    return (
        <Link to={"/project/" + props.data.uid}>
            <Card className="project-card">
                <div style={{ background: color }} className="card-top"></div>
                <div className="p-3 d-flex flex-column h-100">
                    <h6>{props.data.name}</h6>
                    <p>
                        {props.data.description}
                    </p>
                    <p className="mt-auto mb-0">
                        {props.data.date}
                    </p>
                </div>
            </Card>
        </Link>
    )
}
