import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Login from './views/Login';
import { AuthProvider } from './contexts/AuthContext';
import React, { Suspense, lazy } from 'react'
import './css/theme.css'
import Register from './views/Register';
import Dashboard from './views/Dashboard';
import ProtectedRoute from './middlewares/ProtectedRoute';
import UnoriginalRoute from './middlewares/UnoriginalRoute';
import Logout from './views/Logout';
import Handler from './middlewares/Handler';
import { DataProvider } from './contexts/DataContext';
import { MiscProvider } from './contexts/MiscContext';
// import Editor from './views/Editor';

const Editor = lazy(() => import("./views/Editor"));


function App() {
	return (
		<AuthProvider>
			<DataProvider>
				<MiscProvider>
					<div className="App">
						<Router>
							<Routes>
								<Route element={(<UnoriginalRoute />)}>
									<Route element={(<Login />)} path="/login" exact />
									<Route element={(<Register />)} path="/register" exact />

								</Route>
								<Route element={(<Logout />)} path="/logout" exact />
								<Route element={(<Handler />)} path="/handler/:task" exact />

								<Route element={(<ProtectedRoute />)}>
									<Route element={(<Suspense fallback={<></>}>
										<Editor />
									</Suspense>)} path="/project/:uid/*" />

									<Route element={(<Dashboard />)} path="/*" />
								</Route>

								<Route element={(<>404</>)} />
							</Routes>
						</Router>
					</div>
				</MiscProvider>
			</DataProvider>
		</AuthProvider>


	);
}

export default App;
