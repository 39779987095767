import React from 'react';
import '../css/regular_header.css';
import { logo } from "../Assets/Icon";
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function RegularHeader() {
    const {currentUser} = useAuth();
    return (
        <div className="regular-header">
            <div className="header-icon">{logo}</div>
            <div className="ms-3 my-auto">
                <h5 className="mb-0">Ghostengines.io</h5>
            </div>
            <div className="ms-auto my-auto">
                <Link to="/logout">
                    Logout
                </Link>
            </div>
        </div>
    )
}
