import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Handler() {
    const { task } = useParams();
    const [search_params, setSearchParams] = useSearchParams();

    const redirect = search_params.get("redirect");
    const { setName } = useAuth();
    const navigate = useNavigate();

    useEffect(async() => {
        try {
            switch (task) {
                case "update":
                    const name = search_params.get("name");
                    const res = await setName(name);

                    break;

            }
            navigate(redirect)
        }
        catch(err){
            
        }
    }, [])


    return (
        <div>
            
        </div>
    )
}
