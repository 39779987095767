import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_GA_MEASUREMENT

})


const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true
});

export const firebase_obj = firebase;
export const auth = app.auth();
export default app
