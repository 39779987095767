import { Col, Row, Container, Card, FormGroup, Form, Button, Alert, InputGroup } from 'react-bootstrap'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
import { terms, privacy } from '../config';



export default function Register() {

    const [name, setName] = useState("");
    const [name_err, setNameErr] = useState("");
    const [email, setEmail] = useState("");
    const [email_err, setEmailErr] = useState("");
    const [password, setPassword] = useState("");
    const [password_err, setPasswordErr] = useState("");
    const [sub_err, setSubErr] = useState("");

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const { signupEmailPassword } = useAuth();
    const navigate = useNavigate();

    function name_change(e) {
        setName(e.target.value);
        if (!/^[a-z0-9 ]+$/i.test(e.target.value)) setNameErr("Only alpha numerics are allowed in name");
        else if (e.target.length > 128) setNameErr("Name too long");
        else setNameErr("");
    }

    function email_change(e) {
        setEmail(e.target.value);
        if (!isEmail(e.target.value)) setEmailErr("Invalid Email");
        else setEmailErr("");
    }

    function password_change(e) {
        setPassword(e.target.value);
        if (e.target.value.length > 128) setPasswordErr("Password too long");
        else setPasswordErr("");
    }

    async function submit_form(e) {
        if (email.length < 1 || password.length < 1 || name.length < 1) return;
        if (email_err || password_err || name_err) return;
        setLoading(true);
        try {
            const res = await signupEmailPassword(email, password);
            navigate("/handler/update?name=" + name + "&redirect=/");
        }
        catch (err) {
            switch (err.code) {
                case "auth/user-not-found":
                    setSubErr("User is not registered");
                    break;
                default:
                    setSubErr("Invalid credentials");
            }
            setLoading(false);
        }

    }

    const contents = (<>
        <center>
            <h4>Register</h4>
        </center>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={name_change} value={name} type="text" placeholder="Enter name" />
                <Form.Text>
                    {name_err && (name.length > 0) && (<Alert className="mt-3" variant="danger">
                        {name_err}
                    </Alert>)}
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control onChange={email_change} value={email} type="email" placeholder="Enter email" />
                <Form.Text>
                    {email_err && (email.length > 0) && (<Alert className="mt-3" variant="danger">
                        {email_err}
                    </Alert>)}
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                    <Form.Control onChange={password_change} value={password} type={visible ? "text" : "password"} placeholder="Enter password" />
                    <Button onClick={() => setVisible(!visible)} variant="secondary">{visible ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</Button>
                </InputGroup>
            </Form.Group>
            <Form.Text>
                {sub_err && (<Alert className="mt-3" variant="danger">
                    {sub_err}
                </Alert>)}
            </Form.Text>
            <Button disabled={loading} onClick={() => submit_form()} variant="primary">Register</Button>
        </Form>
        <hr></hr>
        <center>Already have an account? <Link to="/login">Login</Link>
            <br></br>
            By proceeding, you agree with our <a href={privacy} target="_blank">Privacy policy</a> and <a href={terms} target="_blank">
                Terms of use
            </a>
        </center>

    </>)
    return (<>
        <Container style={{ height: "100vh", display: "flex" }} className="d-none d-md-flex">
            <Card className="my-auto mx-auto w-50 padded" style={{ maxWidth: "512px" }}>
                {contents}
                {/* <input type="text" /> */}
            </Card>
        </Container>
        <Container style={{ paddingTop: '3em', backgroundColor: 'white', height: '100vh' }} className="d-block d-md-none">
            {contents}
        </Container>
    </>
    )
}
