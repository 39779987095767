import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import {get_auth_data} from "../config";

const DataContext = React.createContext();

export async function protectedfetch(currentUser, url, options){
    const token = await currentUser.getIdToken(true);
    const res = await fetch(url, {...options, headers: new Headers({
        'Authorization' : 'Bearer '  + token,
    })});
    return res;
}

export function useData() {
    return useContext(DataContext);
}

export function DataProvider({children}){
    const {currentUser} = useAuth();
    const [data, setData] = useState(new Promise((resolve, reject) => resolve({})))
    useEffect(async() => {
        if(currentUser){
            setData(new Promise(async (resolve, reject) => {
                const info = await protectedfetch(currentUser, get_auth_data, {});
                if(!info.ok) return reject();
                const json = await info.json();
                return resolve(json)
            }))
            // const data = await protectedfetch(currentUser, get_auth_data, {});
            // setData(await data.json());
        }
        else{
            setData(new Promise(async(resolve, reject) => {
                return resolve({})
            }))
        }
    }, [currentUser])

    function write_data(x){
        setData(new Promise((resolve, reject) => resolve(x)))
    }
    const value = {
        data, 
        write_data
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}