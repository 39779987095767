export const logo = (<svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.106689" y="0.765564" width="95.2345" height="95.2345" rx="11" fill="#6473FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.1658 2.43255C69.9583 9.07241 -2.20744 33.8679 0.395296 48.5233C3.42053 65.5576 49.347 95.7941 49.347 95.7941L85.7963 95.9046C91.1836 95.1926 95.3411 90.5818 95.3411 85V11.7656C95.3411 7.82954 93.2739 4.37636 90.1658 2.43255Z" fill="url(#paint0_linear_9_11)" />
    <defs>
        <linearGradient id="paint0_linear_9_11" x1="95.3572" y1="89.9672" x2="14.6817" y2="44.7417" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6473FF" />
            <stop offset="1" stopColor="#3042E2" />
        </linearGradient>
    </defs>
</svg>
)