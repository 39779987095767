import React, { useState, useEffect } from 'react'
import RegularHeader from '../components/RegularHeader';
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import ProjectCard, { ProjectCardPlaceholder } from '../components/ProjectCard';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import '../css/dash.css'
import CreateProject from './CreateProject';
import RandomGradient from '../Assets/RandomGradients';


function MainBoard() {
    const { currentUser } = useAuth();
    const [res, setRes] = useState({});
    const { data } = useData();

    useEffect(async () => {
        var temp = await data;
        setRes(temp);
    }, [data])

    const projects = res.projects || [];
    const invites = res.invites || [];

    const color = RandomGradient("f")
    const add_project = (<Col md={3} >
        <Link to="/create">
            <div className="project-add card">
                <Button vairant="primary" className="mx-auto my-auto">Create new project</Button>
            </div>
        </Link>

    </Col>)
    
    const project_render = res.uid? [...projects.sort((f, s) => (f.date < s.date) ? 1 : -1).map(p => (<Col className="pb-4" md={3}><ProjectCard data={p} /></Col>)), add_project] : Array(4).fill(<Col className="pb-4" md={3}><ProjectCardPlaceholder /></Col>);
    
    
    return (<>

        <div className="d-flex">
            <h4>My Projects</h4>
            <div className="ms-auto my-auto d-flex">
                <Link to="/create">
                    <Button variant="primary">
                        Create New
                    </Button>
                </Link>
            </div>
        </div>
        <Row className="pt-4 pb-4">
            {project_render}
        </Row>
    </>)
}

export default function Dashboard() {
    return (<>
        <RegularHeader />
        <Container className="pt-5">
            <Routes>
                <Route path="/" element={(<MainBoard />)} />
                <Route path="/create" element={(<CreateProject />)} exact />
            </Routes>
        </Container>
    </>
    )
}
